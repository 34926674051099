
//分享提示
import {ShareOne} from "@icon-park/vue-next";
import {ref} from "vue";

export default {
  name: 'fenXiangTiShi',
  components: {ShareOne},
  setup() {
    const status = ref(false)
    return {
      status
    }
  }
}
